import { request } from "./request";

export function  FeedList(data = {}) {
    return request({
        url: "Feedback/feed_list",
        method: "post",
        data
    });
}
export function FeedSave(data = {}) {
    return request({
        url: "Feedback/feedsave",
        method: "post",
        data
    });
}

export function FeedDel(data = {}) {
    return request({
        url: "Feedback/feeddel",
        method: "post",
        data
    });
}
