import { request } from "./request";

export function Configlist(data = {}) {
    return request({
        url: "Config/configlist",
        method: "post",
        data
    });
}

export function Configedit(data = {}) {
    return request({
        url: "Config/configedit",
        method: "post",
        data
    });
}
export function Configdel(data = {}) {
    return request({
        url: "Config/configdel",
        method: "post",
        data
    });
}

export function Configvalue(data = {}) {
    return request({
        url: "Config/configvalue",
        method: "post",
        data
    });
}

export function Cvlist(data = {}) {
    return request({
        url: "Config/cvlist",
        method: "post",
        data
    });
}