import { request } from "./request";

export function  TypeList(data = {}) {
    return request({
        url: "Type/t_list",
        method: "post",
        data
    });
}
export function TypeSave(data = {}) {
    return request({
        url: "Type/typesave",
        method: "post",
        data
    });
}

export function TypeDel(data = {}) {
    return request({
        url: "Type/typedel",
        method: "post",
        data
    });
}
