import { createRouter, createWebHistory, createWebHashHistory } from "vue-router";
import Index from "../views/Index/Index.vue";
import Home from "../views/Index/Home.vue";
import Login from "../views/Login.vue";
import Userinfo from "../views/Index/Userinfo.vue";
import MenuList from "../views/Bew/MenuList.vue";
import GroupList from "../views/Bewadmin/GroupList.vue";
import UserList from "../views/Bewadmin/UserList.vue";
import Confs from "../views/Index/Confs.vue";
import Config from "../views/Index/Config.vue";
import CateList from "../views/Cate/CateList.vue";
import NewsList from "../views/Cate/NewsList.vue";
import TypeList from "../views/Cate/TypeList.vue";
import Feedback from "../views/Other/Feedback.vue";
import Mail from "../views/Other/Mail.vue";
import AdsList from "../views/Other/AdsList.vue";
import Mailinfo from "../views/Other/Mailinfo.vue";
import Kefu from "../views/Other/Kefu.vue";
const routes = [
    {
        path: "/",
        alias: "/login",
        name: "Login",
        component: Login,
        meta: {
            title: "登录"
        }
    },
    {
        path: "/index",
        name: "Index",
        component: Index,
        meta: {
            title: "首页面板"
        },
        children: [
            {
                path: "home",
                name: "Home",
                component: Home,
                meta: {
                    title: "首页面板"
                }
            },
            {
                path: "userinfo",
                name: "Userinfo",
                component: Userinfo,
                meta: {
                    title: "个人中心"
                }
            },
            {
                path: "menulist",
                name: "MenuList",
                component: MenuList,
                meta: {
                    title: "导航管理"
                }
            },
            {
                path: "grouplist",
                name: "GroupList",
                component: GroupList,
                meta: {
                    title: "部门管理"
                }
            },
            {
                path: "userlist",
                name: "UserList",
                component: UserList,
                meta: {
                    title: "管理员管理"
                }
            },
            {
                path: "confs",
                name: "Confs",
                component: Confs,
                meta: {
                    title: "网站配置"
                }
            },
            {
                path: "config",
                name: "Config",
                component: Config,
                meta: {
                    title: "配置管理"
                }
            },
            {
                path: "catelist",
                name: "CateList",
                component: CateList,
                meta: {
                    title: "分类管理"
                }
            },
            {
                path: "newslist",
                name: "NewsList",
                component: NewsList,
                meta: {
                    title: "内容管理"
                }
            },
            {
                path: "typelist",
                name: "TypeList",
                component: TypeList,
                meta: {
                    title: "分类类型"
                }
            },
            {
                path: "feedback",
                name: "Feedback",
                component: Feedback,
                meta: {
                    title: "信息反馈"
                }
            },
            {
                path: "mail",
                name: "Mail",
                component: Mail,
                meta: {
                    title: "邮箱订阅"
                }
            },
            {
                path: "adslist",
                name: "AdsList",
                component: AdsList,
                meta: {
                    title: "广告管理"
                }
            },
            {
                path: "mailinfo",
                name: "Mailinfo",
                component: Mailinfo,
                meta: {
                    title: "发件设置"
                }
            },
            {
                path: "kefu",
                name: "Kefu",
                component: Kefu,
                meta: {
                    title: "客服登陆"
                }
            }
        ]
    }
];

const router = createRouter({
    // history: createWebHashHistory(process.env.BASE_URL),
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from) => {
    document.title = to.meta.title;
});

export default router;