import { request } from "./request";

export function CateLists(data = {}) {
    return request({
        url: "Cate/cate_list",
        method: "post",
        data
    });
}
export function CateSave(data = {}) {
    return request({
        url: "Cate/catesave",
        method: "post",
        data
    });
}

export function CateDel(data = {}) {
    return request({
        url: "Cate/catedel",
        method: "post",
        data
    });
}

export function CatePx(data = {}) {
    return request({
        url: "Cate/catepx",
        method: "post",
        data
    });
}