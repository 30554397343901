import { request } from "./request";

export function NewsLists(data = {}) {
    return request({
        url: "News/news_list",
        method: "post",
        data
    });
}

export function NewsSave(data = {}) {
    return request({
        url: "News/newssave",
        method: "post",
        data
    });
}

export function NewsDel(data = {}) {
    return request({
        url: "News/newsdel",
        method: "post",
        data
    });
}

export function NewsType(data = {}) {
    return request({
        url: "News/ctype",
        method: "post",
        data
    });
}

export function BigCate(data = {}) {
    return request({
        url: "News/bigcate",
        method: "post",
        data
    });
}

export function CateLists(data = {}) {
    return request({
        url: "News/cate_list",
        method: "post",
        data
    });
}