import { request } from "./request";

export function NewsLists(data = {}) {
    return request({
        url: "Ads/news_list",
        method: "post",
        data
    });
}

export function NewsSave(data = {}) {
    return request({
        url: "Ads/newssave",
        method: "post",
        data
    });
}

export function NewsDel(data = {}) {
    return request({
        url: "Ads/newsdel",
        method: "post",
        data
    });
}


export function BigCate(data = {}) {
    return request({
        url: "Ads/bigcate",
        method: "post",
        data
    });
}
