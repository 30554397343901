<template>
    <div class="sfang">
            <el-link :underline="false"><el-icon  @click="sendData"><FullScreen /><a v-if="sf == false"><Minus /></a></el-icon></el-link>
    </div>
</template>
<script>
import { toRefs,reactive } from "vue";
    export default {
        // props: ['sf'],
        methods: {
            sendData() {
                const data = this.sf;
                this.sf? this.sf=false: this.sf=true;
                this.$emit('customEvent', data); // 触发自定义事件并传递数据
            }
        },
        setup() {
            const state = reactive({
                sf:true,
            });
            
            return {
                ...toRefs(state)
             };    
        }
    }
</script>
<style  >
.sfang{
    display: block;
    float: right;
    margin-top: -64px;
    margin-right: 30px;
}
</style>