import { request } from "./request";
export function Login(data = {}) {
    return request({
        url: "Login/login",
        method: "Post",
        data,
    });
}
export function Getconflists(data = {}) {
    return request({
        url: "Login/conflists",
        method: "post",
        data,
    });
}
