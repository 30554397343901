import { createApp } from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'

import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
// 引入中文包
import locale from 'element-plus/es/locale/lang/zh-cn';
import * as ElementPlusIconsVue from "@element-plus/icons-vue";

const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
}

// 在引入 Element Plus 时，可以传入一个包含 size 和 zIndex 属性的全局配置对象。 
// size 用于设置表单组件的默认尺寸，zIndex 用于设置弹出组件的层级，zIndex 的默认值为 2000。
app.use(store).use(router).use(ElementPlus, { locale , size: "small", zIndex: 3000 }).mount("#app");