import { request } from "./request";

export function  List(data = {}) {
    return request({
        url: "Mail/list",
        method: "post",
        data
    });
}
export function Save(data = {}) {
    return request({
        url: "Mail/save",
        method: "post",
        data
    });
}

export function Del(data = {}) {
    return request({
        url: "Mail/del",
        method: "post",
        data
    });
}
export function Tomail(data = {}) {
    return request({
        url: "Mail/tomail",
        method: "post",
        data
    });
}
export function Mailinfo(data = {}) {
    return request({
        url: "Mail/mailinfo",
        method: "post",
        data
    });
}
export function EditMinfo(data = {}) {
    return request({
        url: "Mail/editmailinfo",
        method: "post",
        data
    });
}