import { request } from "./request.js";
export function Index(data = {}) {
    return request({
        url: "Index/index",
        method: "post",
        data
    });
}
export function Message(data = {}) {
    return request({
        url: "Index/message",
        method: "post",
        data
    });
}
export function UserInfo(data = {}) {
    return request({
        url: "Index/userinfo",
        method: "post",
        data
    });
}

export function ConfInfo(data = {}) {
    return request({
        url: "Conf/conflists",
        method: "post",
        data,
    });
}

export function ConfEdit(data = {}) {
    return request({
        url: "Conf/confedit",
        method: "post",
        data,
    });
}

export function Homeres(data = {}) {
    return request({
        url: "Conf/homeres",
        method: "post",
        data,
    });
}

export function UpPic(data = {}) {
    return request({
        url: "Index/upload_img",
        method: "post",
        data,
    });
}