import { createStore } from 'vuex'

export default createStore({
    state: {
        
    },
    getters: {

    },
    mutations: {

    },
    actions: {

    },
    modules: {

    }
})