<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>
<script>
    export default {
        name: "app"
    };
</script>
<style>
body,
html {
    margin: 0px 0px;
    height: 100%;
}
#app {
    height: 100%;
}
</style>